<template>
  <div>
    <HomePageTop/>
    <div style="margin-top: -32px">
      <header>
        <div>無在庫便ご利用までの流れ</div>
      </header>
      <footer>
        <div class="footerContainer">
          <div class="oneContainer">
            <div class="num">01</div>
            <div class="oneExplainContainer">
              無在庫便サイトは現在の代行サイトと別々の担当者になるため、チャットワークの対応になります。
            </div>
          </div>
          <div class="twoContainer">
            <div class="num">02</div>
            <div class="twoExplainContainer">
              <div class="twoExplainHeader">
                すでにチャットワークをお持ちの場合は下記のIDをユーザー検索し、コンタクト追加をお願いいたします。
              </div>
              <div class="flexAndCenter">
                <img src="../../assets/aboutNotInStockIcon.png" alt="">
                <div>チャットワークID：RAKUMART-CS</div>
              </div>
            </div>
          </div>
          <div class="threeContainer">
            <div class="num">03</div>
            <div class="threeExplainContainer">
              <div>
                チャットワークお持ちでない場合は下記のURLより先にアカウント作成されて（無料）からコンタクト申請してください。
              </div>
              <div @click="pageJump">
                https://www.chatwork.com/service/packages/chatwork/pre_register.php?plan=free&lang=ja
              </div>
            </div>
          </div>
          <div class="fourContainer">
            <div class="num">04</div>
            <div class="fourExplainContainer">
              申請時無在庫販売希望を記入していただければ専用の<br>
              対応グループチャットを作成させて頂きます。お手数をおかけしますがよろしくお願いいたします。
            </div>
          </div>
          <div class="threeContainer" style="margin-bottom: 0">
            <div class="num">05</div>
            <div class="threeExplainContainer">
              <div>連絡取れないまたは操作の分からないお客様は下記のURLよりお問い合わせください。</div>
              <div @click="newPageJump">
                https://www.rakumart-2c.com/#/consult
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    <Foot/>
  </div>
</template>

<script>
import Foot from "../../components/foot/Foot";
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  components: {
    Foot,
    HomePageTop
  },
  methods: {
    pageJump() {
      window.open('https://www.chatwork.com/service/packages/chatwork/pre_register.php?plan=free&lang=ja')
    },
    newPageJump() {
      window.open('https://www.rakumart-2c.com/#/consult')
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  background: url("../../assets/aboutNotInStockBackground.png") no-repeat;
  width: 100%;
  height: 350px;
  background-size: 100% 100%;

  div {
    padding-top: 152px;
    text-shadow: 0 0 27px rgba(0, 0, 0, 0.4);
    font-weight: bold;
    font-size: 48px;
    text-align: center;
    color: #fff;
    letter-spacing: 15px;
  }
}

footer {
  background: #F9F9F9;
  padding: 122px 0 30px;

  .footerContainer {
    width: 1400px;
    margin: 0 auto;
  }

  .num {
    color: #B4272B;
    font-style: italic;
    font-weight: bold;
    font-size: 150px;
  }

  .oneContainer {
    position: relative;
    margin-bottom: 141px;

    .oneExplainContainer {
      width: 1400px;
      height: 150px;
      background: #FFFFFF;
      box-shadow: 0px 0px 18px 2px rgba(7, 0, 2, 0.05);
      opacity: 0.95;
      border-radius: 20px;
      padding-left: 48px;
      line-height: 150px;
      color: #000000;
      font-size: 24px;
    }

    .num {
      position: absolute;
      top: -95px;
      left: 58px;
    }
  }

  .twoContainer {
    position: relative;
    margin-bottom: 141px;

    .twoExplainContainer {
      width: 1400px;
      height: 280px;
      background: #FFFFFF;
      box-shadow: 0 0 18px 2px rgba(7, 0, 2, 0.05);
      opacity: 0.95;
      border-radius: 20px;
      padding: 63px 50px 40px;
      box-sizing: border-box;

      .twoExplainHeader {
        color: #000000;
        font-size: 24px;
        padding-bottom: 40px;
        border-bottom: 1px solid #DFDFDF;
      }

      .flexAndCenter {
        justify-content: center;
        padding-top: 40px;

        img {
          margin-right: 30px;
          cursor: pointer;
        }

        div {
          color: #000000;
          font-size: 24px;
        }
      }
    }

    .num {
      position: absolute;
      top: -95px;
      right: 60px;
    }
  }

  .threeContainer {
    position: relative;
    margin-bottom: 141px;

    .threeExplainContainer {
      width: 1400px;
      height: 280px;
      background: #FFFFFF;
      box-shadow: 0 0 18px 2px rgba(7, 0, 2, 0.05);
      opacity: 0.95;
      border-radius: 20px;
      padding: 63px 50px 40px;
      box-sizing: border-box;

      div:first-child {
        color: #000000;
        font-size: 23px;
        padding-bottom: 40px;
        border-bottom: 1px solid #DFDFDF;
      }

      div:last-child {
        padding-top: 40px;
        color: #B4272B;
        font-size: 24px;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .num {
      position: absolute;
      top: -95px;
      left: 58px;
    }
  }

  .fourContainer {
    position: relative;
    margin-bottom: 141px;

    .fourExplainContainer {
      padding-left: 39px;
      line-height: 36px;
      color: #000000;
      font-size: 24px;
      width: 1400px;
      height: 185px;
      background: #FFFFFF;
      box-shadow: 0 0 18px 2px rgba(7, 0, 2, 0.05);
      opacity: 0.95;
      border-radius: 20px;
      padding-top: 63px;
    }

    .num {
      position: absolute;
      top: -95px;
      right: 60px;
    }
  }
}
</style>
